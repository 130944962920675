import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {MatDialog} from '@angular/material/dialog';
import {DateRangePickerModalComponent} from '../date-range-picker-modal/date-range-picker-modal.component';
import {ReadingsHelper} from '../shared/helper/readings-helper';

@Component({
  selector: 'app-readings-panel',
  templateUrl: './readings-panel.component.html',
  styleUrls: ['./readings-panel.component.css']
})
export class ReadingsPanelComponent implements OnInit {

  displayedColumns: string[] = ['time', 'values'];
  dataSource = [];
  loading = true;
  reloading = false;

  @Input()
  serialNumber: string;

  @Input()
  funkKz: string;

  @Input()
  endTimestamp: any;

  from: any;
  comment: any;

  page = 0;

  constructor(public dialog: MatDialog, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.getLastReadings(this.serialNumber, this.funkKz, new Date(this.endTimestamp).getTime())
    .subscribe((res: any) => {
      if (!res.commment) {
        this.dataSource = ReadingsHelper.parseReadings(res.readings);
        this.from = res.from;
      } else {
        this.comment = res.comment;
      }
      this.loading = false;
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(DateRangePickerModalComponent, {
      data: {serialNumber: this.serialNumber, funkKz: this.funkKz},
      disableClose: true,
      panelClass: 'dateRangeModalPanel'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result && 'readings' in result) {
        this.dataSource = this.dataSource.concat(result['readings'])
        .sort((a, b) => b.time - a.time)
        .filter((item, index, self) => self.findIndex(t => t.time === item.time) === index);

        this.from = this.dataSource[this.dataSource.length - 1].time;
        this.endTimestamp = this.dataSource[0].time;
      }
    });
  }

  loadMore() {
    this.reloading = true;

    let endTimestamp = ReadingsPanelComponent.getDayBefore(this.from).getTime();
    this.dataService.getLastReadings(this.serialNumber, this.funkKz, endTimestamp)
    .subscribe((res: any) => {
      if (!res.comment) {
        this.dataSource = this.dataSource.concat(ReadingsHelper.parseReadings(res.readings));
        this.from = res.from;
      } else {
        this.comment = res.comment;
      }
      this.reloading = false;
    })
  }

  private static getDayBefore(timestamp) {
    let date = new Date(timestamp);
    date.setDate(date.getDate() - 1);
    return date;
  }

}
