import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoginService} from '../shared/services/login.service';

@Injectable()
export class AuthGuard implements CanActivate {
  loginStatus: boolean;

  constructor(
      private router: Router,
      public loginService: LoginService
  ) {
  }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot) {
    this.loginStatus = this.loginService.isLoggedIn(next.params['id']);
    if (this.loginStatus === true) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
