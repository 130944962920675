<div class="readings-panel">
	<span class="readings-header">
		Messungen
	</span>
  <span class="readings-header" *ngIf="!!from">
		aus dem Zeitraum zwischen {{from | date:'dd.MM.yyyy'}} und {{endTimestamp | date:'dd.MM.yyyy HH:mm:ss'}}
	</span>

  <loading-screen *ngIf="loading"></loading-screen>

  <div class="scrollable-panel" *ngIf="!loading && !!(dataSource && dataSource.length)">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Zeitstempel</th>
        <td mat-cell *matCellDef="let reading"> {{reading.time | date:'dd.MM.yyyy HH:mm:ss'}} </td>
      </ng-container>

      <ng-container matColumnDef="values">
        <th mat-header-cell *matHeaderCellDef>Werte</th>
        <td mat-cell *matCellDef="let element"> {{element.values | json}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>

  <div *ngIf="!loading && !!comment" class="readings-comment">
    {{comment}}
  </div>

  <div *ngIf="!loading && !comment">
    <div class="buttons">
      <button class="btn btn-primary" (click)="loadMore()" [disabled]="reloading"><span *ngIf="!reloading">Mehr Messungen laden</span>
        <i *ngIf="reloading"
           class="fas fa-spinner fa-spin"></i>
      </button>
      <button class="btn btn-primary" (click)="openDialog()" [disabled]="reloading">Zeitraum auswählen</button>
    </div>
  </div>
</div>
