import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../shared/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginFail: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private loginService: LoginService) {
    this.loginFail = false;
    this.route.queryParams.subscribe((queryParams: any) => {
      this.loginFail = queryParams.error != null ? queryParams.error : false;
    });
  }

  login(lienr: String, pwd: String) {
    if (Number(lienr) && lienr === pwd) {
      this.loginService.login(lienr, pwd);
      this.router.navigate(['/table/' + lienr]);
    } else {
      this.router.navigate(['/login'], {queryParams: {error: true}});
    }
  }
}
