<div *ngIf="isLoading && !error">
  <loading-screen></loading-screen>
</div>

<div *ngIf="!isLoading && error && autoLogin" class="wrapper">
  <app-not-found-page></app-not-found-page>
</div>

<div *ngIf="!isLoading && !error">
  <div *ngIf="liegenschaft && liegenschaft['liegenschaftsNr'] !=0 && !autoLogin" class="buttonsContainer">
    <div class="buttons">
      <button class="btn btn-primary" (click)="backToLogin()">Liegenschaft wechseln</button>
      <button class="btn btn-primary" (click)="getMeters(liegenschaft['liegenschaftsNr'])"><span *ngIf="!isReloading">Aktualisieren</span>
        <i *ngIf="isReloading"
           class="fas fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>

  <app-liegenschaft-details [liegenschaft]="liegenschaft"></app-liegenschaft-details>

  <app-gateways-panel [gateways]="liegenschaft && liegenschaft['gateways']" *ngIf="!isReloading && liegenschaft"
                      (onSelectionUpdate)="triggerGeraeteOfSelectedGatewaysUpdate($event)"
                      (onConnectedDevicesUpdate)="updateConnectedDevices($event)"></app-gateways-panel>

  <div *ngIf="isReloading" class="portfolio outerContainer">
    <loading-screen></loading-screen>
  </div>

  <mat-tab-group mat-stretch-tabs *ngIf="antasGeraete && !isReloading" class="tabs" animationDuration="0ms">
    <mat-tab [label]="'Geräte (' + antasGeraeteOfSelectedGateways.length + ')'">
      <app-geraete-liste [geraete]="antasGeraeteOfSelectedGateways" [reloadingGerate]="isReloadingGeraete"></app-geraete-liste>
    </mat-tab>
    <mat-tab [label]="'Unbekannte Geräte (' + nonAntasGeraeteOfSelectedGateways.length + ')'">
      <app-unbekannte-geraete-liste [geraete]="nonAntasGeraeteOfSelectedGateways" [reloadingGerate]="isReloadingGeraete"></app-unbekannte-geraete-liste>
    </mat-tab>
  </mat-tab-group>

  <div class="portfolio outerContainer" *ngIf="liegenschaft && liegenschaft['liegenschaftsNr'] != 0">
    <h1></h1>
  </div>

</div>
