import {Component, Input} from '@angular/core';

@Component({
  selector: 'sorting-arrow',
  template: `<span [ngClass]="[\'sorting-arrow\', getActiveClass(), getSortingClass()]"
                   (click)="onClick()">
                
               </span>`,
  styleUrls: ['sorting-arrow.component.css']
})
export class SortingArrowComponent {
  // the global field that is being currently sorted on
  @Input() fieldGlobal: string;

  // the field that this widget should enable for sorting
  @Input() fieldLocal: string;

  // current sorting (asc/desc)
  @Input() sorting: number;

  // method to call, when the sorting is changed
  @Input() changeSortingCallback: (field: string, sorting: number) => void;

  onClick() {
    if (this.fieldLocal === this.fieldGlobal) {
      // it's already being sorted on this field
      if (this.sorting === 1) {
        this.sorting = -1;
      } else {
        this.sorting = 1;
      }
    }

    this.changeSortingCallback(this.fieldLocal, this.sorting);
  }

  getActiveClass(): string {
    if (this.fieldGlobal === this.fieldLocal) {
      return 'sorting-active';
    } else {
      return 'sorting-inactive';
    }
  }

  getSortingClass(): string {
    if (this.sorting === 1) {
      return 'sorting-asc';
    } else {
      return 'sorting-desc';
    }
  }
}
