import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {DataService} from '../data.service'
import {DatePipe} from '@angular/common'

@Component({
  selector: 'app-gateway-tile',
  templateUrl: './gateway-tile.component.html',
  styleUrls: ['./gateway-tile.component.css'],
  providers: [DatePipe]
})
export class GatewayTileComponent implements OnInit {

  @Input()
  gateway: {
    geraetenummer?: string,
    checkable?: boolean,
    connectedDevices?: any[]
  } = {}

  @Output()
  onSelect = new EventEmitter<boolean>()

  @Output()
  onConnectedDevicesUpdate = new EventEmitter<any>()

  model = {
    iconClass: 'loading',
    summary: 'Wird geladen...',
    summaryClass: '',
    tileClass: 'non-selectable',
    selected: false
  }

  constructor(private dataService: DataService,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    if (this.gateway.checkable)
      this.loadGatewayStatus()
    else {
      this.model.iconClass = 'gateway-old'
      this.model.summary = 'Gateway vom alten Typ'
    }
  }

  select(): void {
    if (this.gateway.connectedDevices != null) {
      this.model.selected = !this.model.selected
      this.model.tileClass = this.model.selected ? 'selected' : 'unselected'
      this.onSelect.emit(this.model.selected)
    }
  }

  private loadGatewayStatus() {
    this.dataService.getGatewayData(this.gateway.geraetenummer).subscribe(
        (res: any) => {
          if (res.status === 'OK') {
            this.model.summaryClass = ''
            this.model.iconClass = 'gateway-ok'
            this.model.summary = this.formatDate(res)
          } else {
            this.model.summaryClass = 'summary-error'
            this.model.iconClass = 'gateway-warning'
            this.model.summary = res.status + '<br/>' + this.formatDate(res)
          }

          this.gateway.connectedDevices = res.connectedDevices
          .map(cd => ({...cd, gateway: this.gateway.geraetenummer}))
          if (!!this.gateway.connectedDevices && !!this.gateway.connectedDevices.length)
            this.onConnectedDevicesUpdate.emit(this.gateway.connectedDevices)
          this.model.tileClass = 'unselected'
        },
        (err: any) => {
          this.model.iconClass = 'gateway-error'
          this.model.summaryClass = 'summary-error'
          this.model.tileClass = 'non-selectable'

          if (err.status == 400)
            this.model.summary = 'Nicht gefunden. Seriennummer korrekt?'
          else if (err.status == 403)
            this.model.summary = 'Kein Zugriff. Bei DCGY melden.'
          else
            this.model.summary = 'Konnte nicht geladen werden.'
        }
    )
  }

  private formatDate(res: any) {
    return res.time ? this.datePipe.transform(new Date(parseInt(res.time)), 'dd.MM.yyyy HH:mm:ss') : ''
  }

}
