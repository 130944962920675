import {Component, Input, OnInit} from '@angular/core'
import {AbstractGerateListeComponent} from '../shared/helper/abstract-geraete-liste/abstract-gerate-liste-component'

@Component({
  selector: 'app-unbekannte-geraete-liste',
  templateUrl: './unbekannte-geraete-liste.component.html',
  styleUrls: ['./unbekannte-geraete-liste.component.css']
})
export class UnbekannteGeraeteListeComponent extends AbstractGerateListeComponent implements OnInit {

  @Input()
  geraete: any[]

  @Input()
  reloadingGerate: boolean = false

  getGeraete() {
    return this.geraete
  }

}
