<div *ngIf="!reloadingGerate" class="portfolio outerContainer">
  <div class="table monteur_list">
    <div class="table-row table-header">
      <div class="table-row-item">
					<span class="immobilie_number">
						Nutzeinheits-
						<br>Nummer
					</span>
        <sorting-arrow [sorting]="-1" [changeSortingCallback]="getUpdateSortingMethod()"
                       fieldLocal="nutzeinheitsnummer" [fieldGlobal]="sortingField">
        </sorting-arrow>
      </div>
      <div class="table-row-item">
					<span class="immobilie_we">
						Lage
					</span>
      </div>
      <div class="table-row-item">
					<span class="immobilie_we">
						Raum
					</span>
      </div>
      <div class="table-row-item">
					<span class="immobilie_we">
						Gerätetyp
					</span>
      </div>
      <div class="table-row-item">
					<span class="immobilie_number">
						Sender
						<br>(Gerätenummer)
					</span>
      </div>
      <div class="table-row-item">
					<span class="immobilie_we">
						Sendeleistung
					</span>
        <sorting-arrow [sorting]="1" [changeSortingCallback]="getUpdateSortingMethod()" fieldLocal="sendeleistung"
                       [fieldGlobal]="sortingField">
        </sorting-arrow>
      </div>
      <div class="table-row-item">
        <span class="immobilie_we">Status</span>
      </div>
      <div class="table-row-item">
					<span class="immobilie_number">
						Zuletzt Online
					</span>
        <sorting-arrow [sorting]="1" [changeSortingCallback]="getUpdateSortingMethod() " fieldLocal="measureTime"
                       [fieldGlobal]="sortingField">
        </sorting-arrow>
      </div>
      <div class="table-row-item">
        <span class="immobilie_we">Messungen</span>
      </div>
    </div>

    <ng-container *ngFor="let meter of geraete">
      <div class="table-row  immobilie_entry"
           [ngClass]="meter['measureTime'] < comparisonDate ? 'stoerung' : ''"
      >
        <div class="table-row-item ">
					<span class="immobilie_number">
						{{meter['nutzeinheitsnummer']}}
					</span>
        </div>
        <div class="table-row-item ">
					<span class="immobilie_we">
						{{meter['lage']}}
					</span>
        </div>
        <div class="table-row-item ">
					<span class="immobilie_we">
						{{meter['raum']}}
					</span>
        </div>
        <div class="table-row-item ">
					<span class="immobilie_we">
						{{meter['gertyp']}}
					</span>
        </div>
        <div class="table-row-item ">
					<span class="immobilie_number">
						{{meter['geraetenummer']}}
					</span>
        </div>
        <div class="table-row-item ">
					<span class="immobilie_we"
                *ngIf="!!meter[ 'measureTime'] && meter[ 'sendeleistung'] !=='NaN' else elseBlockNaN">
						{{meter['sendeleistung']}} dB
					</span>
        </div>
        <ng-template #elseBlockNaN>
          <span class="immobilie_we" *ngIf="!!meter[ 'measureTime'] && !meter[ 'messung'] else elseBlock"
                matTooltip="Die Sendeleistung ist zu dem Gerätetyp nicht abrufbar">
            {{meter['sendeleistung']}}
          </span>
        </ng-template>
        <ng-template #elseBlock>
          <div>
              <span class="i_attention_rot table-icon"
                    [matTooltip]="getTooltipText(meter.geraetenummer, 'signalStrengthError')" matTooltipHideDelay="500"
                    matTooltipPosition="after"> </span>
          </div>
        </ng-template>
        <div class="table-row-item wrapped">
          <sending-bars *ngIf="meter['sendeleistung'] !=='NaN' else elseStatus"
                        [status]="calculateStatus(meter['sendeleistung']) ">
          </sending-bars>
          <ng-template #elseStatus>
            <div *ngIf="meter['messung']">Sendeleistung nicht verfügbar. Letzte erhaltene Messung:</div>
          </ng-template>
        </div>
        <div class="table-row-item">
					<span *ngIf="!!meter['measureTime'] else elseDate"
                [ngClass]="meter['measureTime'] < comparisonDate ? 'immobilie_number texterr' : 'immobilie_number'">
						{{meter['measureTime'] | date:'dd.MM.yyyy HH:mm:ss'}}
					</span>
          <ng-template #elseDate>
            <span> </span>
          </ng-template>
        </div>
        <div class="table-row-item right">
          <div
              *ngIf="mayHaveReadings(meter)"
              (click)="toggleReadings(meter)"
          >
            <span class="i_readings table-icon"></span>
          </div>
        </div>
      </div>
      <app-readings-panel *ngIf="meter.showReadings" [serialNumber]="meter.geraetenummer" [funkKz]="meter.funkKz"
                          [endTimestamp]="meter.measureTime"></app-readings-panel>
    </ng-container>
  </div>

</div>

<div *ngIf="reloadingGerate" class="portfolio outerContainer">
  <loading-screen></loading-screen>
</div>
