import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../shared/services/login.service';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.css']
})
export class AutoLoginComponent implements OnInit {

  isLoading: boolean = true;
  private lsNr: string = null;

  constructor(private router: Router, private route: ActivatedRoute, private loginService: LoginService) {
    this.loginService.logout();
    this.route.queryParams.subscribe((queryParams: any) => {
      this.lsNr = queryParams['lsNr'];
    });
  }

  ngOnInit(): void {
    if (Number(this.lsNr)) {
      this.loginService.login(this.lsNr, this.lsNr);
      this.router.navigate(['/table/' + this.lsNr], {queryParams: {autoLogin: true}})
    }
    this.isLoading = false;
  }

}
