import {Injectable} from '@angular/core';

@Injectable()
export class LoginService {

  constructor() {
  }

  login(lienr: String, pwd: String): boolean {
    if (Number(lienr) && lienr === pwd) {
      sessionStorage.setItem('userId', lienr.toString());
      return true;
    }
    return false;
  }

  logout() {
    sessionStorage.removeItem('userId');
  }

  isLoggedIn(lienr: String): boolean {
    if (sessionStorage.getItem('userId') === lienr.toString()) {
      return true;
    }
    return false;
  }
}
