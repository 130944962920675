import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {environment} from '../environments/environment';
import {Observable} from 'rxjs';


@Injectable()
export class DataService {

  constructor(private http: HttpClient) {
  }

  getMonteur(lienr: String): Observable<Object> {
    return this.http.get(environment.baseUrl + 'monteur/' + lienr,
        {headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')}
    );
  }

  getGatewayData(geraeteNr: string) {
    return this.http.get(
        environment.baseUrl + 'monteur/connectedDevices' +
        '?gatewaySerialNumber=' + geraeteNr,
        {headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')}
    )
  }

  getLastReadings(geraeteNr: string, funkKz: string, endTimestamp: number) {
    return this.http.get(
        environment.baseUrl + 'monteur/last-readings' +
        '?serialNumber=' + geraeteNr +
        '&funkKz=' + funkKz +
        '&endTimestamp=' + endTimestamp,
        {headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')}
    )
  }

  getReadingsInDateRange(geraeteNr: string, funkKz: string, startTimestamp: string, endTimestamp: string) {
    return this.http.get(
        environment.baseUrl + 'monteur/readings' +
        '?serialNumber=' + geraeteNr +
        '&funkKz=' + funkKz +
        '&startTimestamp=' + startTimestamp +
        '&endTimestamp=' + endTimestamp,
        {headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')}
    )
  }

}
