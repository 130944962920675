import {Component, Input} from '@angular/core';

@Component({
  selector: 'sending-bars',
  templateUrl: './sending-bars.component.html',
  styleUrls: ['./sending-bars.component.css']
})
export class SendingBarsComponent {
  @Input() status: string;

  color1: string = '#f00'; // red
  color2: string = '#ffa500'; // orange
  color3: string = '#ffff99'; // yellow
  color4: string = '#ccffcc'; // dark green
  color5: string = '#ccffcc'; // light green
  defaultColor: string = '#fff'; // white

  constructor() {
  }

  backgroundC1() {
    if (this.status == '1') {
      return this.color1;
    } else if (this.status == '2') {
      return this.color2;
    } else if (this.status == '3') {
      return this.color3;
    } else if (this.status == '4') {
      return this.color4;
    } else if (this.status == '5') {
      return this.color5;
    }
    return this.defaultColor;
  }

  backgroundC2() {
    if (this.status == '2') {
      return this.color2;
    } else if (this.status == '3') {
      return this.color3;
    } else if (this.status == '4') {
      return this.color4;
    } else if (this.status == '5') {
      return this.color5;
    }
    return this.defaultColor;
  }

  backgroundC3() {
    if (this.status == '3') {
      return this.color3;
    } else if (this.status == '4') {
      return this.color4;
    } else if (this.status == '5') {
      return this.color5;
    }
    return this.defaultColor;
  }

  backgroundC4() {
    if (this.status == '4') {
      return this.color4;
    } else if (this.status == '5') {
      return this.color5;
    }
    return this.defaultColor;
  }

  backgroundC5() {
    if (this.status == '5') {
      return this.color5;
    }
    return this.defaultColor;
  }

}
