import {Component, OnInit} from '@angular/core'
import {DataService} from '../data.service'
import {ActivatedRoute, Router} from '@angular/router'
import {scheduled, Subject} from 'rxjs'
import {async} from 'rxjs/internal/scheduler/async'
import {debounceTime} from 'rxjs/operators'

@Component({
  selector: 'app-monteurportal',
  templateUrl: './monteurportal.component.html',
  styleUrls: ['./monteurportal.component.css']
})
export class MonteurportalComponent implements OnInit {

  liegenschaft: Object
  id: String
  error: boolean = false
  autoLogin: boolean = false
  isLoading: boolean = true
  isReloading: boolean = false
  isReloadingGeraete: boolean = false

  antasGeraete = []
  gatewaysGeraete = []

  anyGatewaySelected = false

  antasGeraeteOfSelectedGateways = []
  nonAntasGeraeteOfSelectedGateways = []

  updateGeraeteOfSelectedGatewaysSubject$ = new Subject<any>()

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id']
    })

    this.route.queryParams.subscribe(queryParams => {
      this.autoLogin = !!queryParams['autoLogin']
    })

    this.getMeters(this.id)

    this.updateGeraeteOfSelectedGatewaysSubject$.asObservable().pipe(
        debounceTime(1500)
    ).subscribe(e => {
      this.isReloadingGeraete = true
      this.updateGeraeteOfSelectedGatewaysAsynchronously(e)
    })
  }

  getMeters(lienr: String): void {
    this.isReloading = true
    this.dataService.getMonteur(lienr).subscribe(response => {
          this.liegenschaft = response

          this.antasGeraete = this.liegenschaft['geraete']
          this.antasGeraeteOfSelectedGateways = this.antasGeraete
          this.nonAntasGeraeteOfSelectedGateways = []

          this.isLoading = false
          this.isReloading = false
        },
        () => {
          if (!this.autoLogin)
            this.router.navigate(['/login'], {queryParams: {error: true}})
          else {
            this.error = true
            this.isLoading = false
            this.isReloading = false
          }
        }
    )
  }

  triggerGeraeteOfSelectedGatewaysUpdate(event) {
    this.updateGeraeteOfSelectedGatewaysSubject$.next(event)
  }

  private updateGeraeteOfSelectedGatewaysAsynchronously(event) {
    scheduled([event], async)
    .subscribe(e => {
      if (e.anyGatewaySelected != null)
        this.anyGatewaySelected = e.anyGatewaySelected

      if (this.anyGatewaySelected) {
        this.antasGeraeteOfSelectedGateways = this.antasGeraete.filter(g => e.connectedDevices.filter(cd => MonteurportalComponent.equals(g, cd)).length !== 0)
        this.nonAntasGeraeteOfSelectedGateways = MonteurportalComponent.findUnknownGeraete(e.connectedDevices, this.antasGeraete)
      } else {
        this.antasGeraeteOfSelectedGateways = this.antasGeraete
        this.nonAntasGeraeteOfSelectedGateways = MonteurportalComponent.findUnknownGeraete(this.gatewaysGeraete, this.antasGeraete)
      }
      this.antasGeraete.forEach(ag => ag.showReadings = false);
      this.isReloadingGeraete = false
    })
  }

  private static equals(geraet, connectedDevice) {
    return connectedDevice.funkKz === geraet.funkKz && connectedDevice.geraeteNr === geraet.geraetenummer
  }

  private static findUnknownGeraete(connectedDevices, allGeraete) {
    return connectedDevices.filter(cd => allGeraete.filter(g => MonteurportalComponent.equals(g, cd)).length === 0)
    .map(cd => ({
      gateway: cd.gateway,
      geraetenummer: cd.geraeteNr || cd.deviceSerialNumber,
      funkKz: cd.funkKz,
      sendeleistung: cd.signalStrength,
      measureTime: cd.lastSeenTime,
      meterIdAvailable: cd.meterIdAvailable
    }))
  }

  updateConnectedDevices(connectedDevices) {
    this.gatewaysGeraete = connectedDevices
    this.triggerGeraeteOfSelectedGatewaysUpdate([{}])
  }

  backToLogin(): void {
    this.router.navigate(['/login'])
  }

}
