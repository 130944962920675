<div class="container">
  <div class="row">
    <div class="login outerContainer">
      <h1 class="">Login MontageCheck</h1>
      <!-- loginContainer -->
      <div class="loginContainer">

        <form name="form" (keyup.enter)="login(userInput.value, passInput.value)" novalidate>
          <div class="row">
            <div class="form-group col-sm-12 col-md-12">
              <label for="username">Liegenschaftsnummer</label>
              <input #userInput type="text" class="form-control" name="username" required placeholder="Nummer ..."/>
              <!--					<div class="help-block">Username is required</div>-->
              <label for="password">Passwort</label>
              <input #passInput type="password" class="form-control" name="password" required placeholder="Passwort ..."/>
              <!--					<div class="help-block">Password is required</div>-->
            </div>
          </div>

          <div class="form-group row">
            <div class="form-group col-sm-12 col-md-12">
              <button class="btn btn-primary" type="button" (click)="login(userInput.value, passInput.value)">Anmelden</button>
            </div>
          </div>

        </form>
        <div class="alert alert-danger" *ngIf="loginFail">Falsche Logindaten!</div>

        <!--
        <div class="loginAddition row">
          <p> Haben Sie Ihre Zugangsdaten vergessen? </p>
          <a href="#">Hier klicken</a>
        </div>
         -->
      </div>
      <!-- loginContainer ENDE-->
    </div>
  </div>
</div>
