import {Component} from '@angular/core';

@Component({
  selector: 'loading-screen',
  template: '<div class="loading-screen"></div>',
  styleUrls: ['loading-screen.component.css']
})
export class LoadingScreenComponent {

}
