import {Component} from '@angular/core';

@Component({
  template: ''
})
export abstract class AbstractGerateListeComponent {

  sortingField: string = 'nutzeinheitsnummer';
  sortingType: number = 1;
  comparisonDate: Date = new Date();

  abstract getGeraete();

  ngOnInit(): void {
    this.comparisonDate.setDate(this.comparisonDate.getDate() - 1);
  }

  getUpdateSortingMethod() {
    return (field: string, sorting: number) => {
      this.sortingField = field;
      this.sortingType = sorting;
      this.updateNutzeinheitCollections();
    };
  }

  getTooltipText(geraetId: number, errorType: String): String {
    const geraete: Array<{ geraetenummer?: number, errorMessages?: Object }> = <Array<any>>this.getGeraete();
    const result = geraete.find(el => geraetId === el.geraetenummer);
    return result.errorMessages && result.errorMessages[errorType.toString()];
  }

  toggleReadings(meter) {
    meter.showReadings = !meter.showReadings;
  }

  calculateStatus(signalStrength) {
    if (signalStrength == null) {
      return '0'
    } else if (signalStrength > -30.0) {
      return '5'
    } else if (signalStrength > -60.0) {
      return '4'
    } else if (signalStrength > -90.0) {
      return '3'
    } else if (signalStrength > -100.0) {
      return '2'
    } else {
      return '1'
    }
  }

  public mayHaveReadings(meter) {
    return !!meter.measureTime && meter.meterIdAvailable !== false;
  }

  private getNutzeinheitComparator() {
    return (t1, t2) => this.comparator(t2, t1);
  }

  private comparator(t1, t2) {
    let comp1 = t1[this.sortingField] === 'NaN' ? -Number.MAX_VALUE : t1[this.sortingField];
    let comp2 = t2[this.sortingField] === 'NaN' ? -Number.MAX_VALUE : t2[this.sortingField];

    if (comp1 === comp2) {
      comp1 = t1['geraetenummer'];
      comp2 = t2['geraetenummer'];
    }
    if (comp1 < comp2) return -this.sortingType;
    if (comp1 > comp2) return this.sortingType;
    return 0;
  }

  private updateNutzeinheitCollections() {
    this.getGeraete().sort(this.getNutzeinheitComparator());
  }

}
