export class ReadingsHelper {
  static parseReadings(readings: string) {
    let parsedReadings = JSON.parse(readings);
    if (Array.isArray(parsedReadings)) {
      return parsedReadings.sort((a, b) => b.time - a.time);
    } else {
      return [];
    }
  }
}
