import {NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LoginComponent} from './login/login.component';
import {MonteurportalComponent} from './monteurportal/monteurportal.component';
import {AuthGuard} from './guards/auth.guard';
import {AutoLoginComponent} from './auto-login/auto-login.component';

const routes: Routes = [
  {
    path: '', component: LoginComponent
  },
  {path: 'login', component: LoginComponent},
  {path: 'auto-login', component: AutoLoginComponent},
  {path: 'table/:id', component: MonteurportalComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
