import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-liegenschaft-details',
  templateUrl: './liegenschaft-details.component.html',
  styleUrls: ['./liegenschaft-details.component.css']
})
export class LiegenschaftDetailsComponent {

  @Input()
  liegenschaft: any;

}
