import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../data.service';
import {ReadingsHelper} from '../shared/helper/readings-helper';
import {forkJoin, timer} from 'rxjs';

@Component({
  selector: 'app-date-range-picker-modal',
  templateUrl: './date-range-picker-modal.component.html',
  styleUrls: ['./date-range-picker-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangePickerModalComponent {

  startDate: string;
  endDate: string;

  private serialNumber: string;
  private funkKz: string;

  validationMessage: string = null;

  validate = false;

  loading = false;

  constructor(
      private dataService: DataService,
      private dialogRef: MatDialogRef<DateRangePickerModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.serialNumber = data.serialNumber;
    this.funkKz = data.funkKz;
  }

  validateValues() {
    if (!this.validate)
      return;

    if (!this.startDate || !this.endDate) {
      this.validationMessage = 'Beginndatum und Enddatum sollten ausgewählt werden.';
      return;
    }

    let from = new Date(this.startDate);
    let to = new Date(this.endDate);
    let maxRange = DateRangePickerModalComponent.addDaysToDate(from, 14);

    if (from > to) {
      this.validationMessage = 'Enddatum soll nach dem Beginndatum sein.';
    } else if (to >= maxRange) {
      this.validationMessage = 'Die Auswahl des Zeitraums wird auf 14 Tage beschränkt';
    } else {
      this.validationMessage = null;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  load() {
    this.validate = true;
    this.validateValues();

    if (!this.validationMessage) {
      this.loading = true;
      forkJoin(
          [this.dataService.getReadingsInDateRange(this.serialNumber, this.funkKz, this.startDate, this.endDate),
            timer(500)]
      )
      .subscribe((res: any) => {
        let queryRes = res[0] || [];

        if (!queryRes.comment) {
          let readings = ReadingsHelper.parseReadings(queryRes.readings);

          if (readings.length > 0) {
            this.dialogRef.close({
              readings: readings
            });
          } else {
            this.validationMessage = 'Keine Messwerte vorhanden';
          }
        } else {
          this.validationMessage = queryRes.comment;
        }

        this.loading = false;
      })
    }
  }

  private static addDaysToDate(date: Date, daysNumber: number): Date {
    let updatedDate = new Date(date.getTime());
    updatedDate.setDate(updatedDate.getDate() + daysNumber);
    return updatedDate;
  }
}
