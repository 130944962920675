import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {LoginComponent} from './login/login.component';
import {AppComponent} from './app.component';
import {MonteurportalComponent} from './monteurportal/monteurportal.component';
import {DataService} from './data.service';
import {SendingBarsComponent} from './sending-bars/sending-bars.component';
import {LoadingScreenComponent} from './shared/helper/loading-screen/loading-screen.component';
import {SortingArrowComponent} from './shared/helper/sorting-arrow/sorting-arrow.component';
import {LoginService} from './shared/services/login.service';
import {AuthGuard} from './guards/auth.guard';
import {MatCardModule} from '@angular/material/card';
import {GatewayTileComponent} from './gateway-tile/gateway-tile.component';
import {GatewaysPanelComponent} from './gateways-panel/gateways-panel.component';
import {LiegenschaftDetailsComponent} from './liegenschaft-details/liegenschaft-details.component';
import {GeraeteListeComponent} from './geraete-liste/geraete-liste.component';
import {MatTabsModule} from '@angular/material/tabs';
import {UnbekannteGeraeteListeComponent} from './unbekannte-geraete-liste/unbekannte-geraete-liste.component';
import {ReadingsPanelComponent} from './readings-panel/readings-panel.component';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {DateRangePickerModalComponent} from './date-range-picker-modal/date-range-picker-modal.component';
import {FormsModule} from '@angular/forms';
import {AutoLoginComponent} from './auto-login/auto-login.component';
import {NotFoundPageComponent} from './shared/not-found-page/not-found-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MonteurportalComponent,
    SendingBarsComponent,
    LoadingScreenComponent,
    SortingArrowComponent,
    GatewayTileComponent,
    GatewaysPanelComponent,
    LiegenschaftDetailsComponent,
    GeraeteListeComponent,
    UnbekannteGeraeteListeComponent,
    ReadingsPanelComponent,
    DateRangePickerModalComponent,
    AutoLoginComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    FormsModule
  ],
  providers: [DataService, LoginService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
}
