import {Component, Input, OnInit} from '@angular/core'
import {AbstractGerateListeComponent} from '../shared/helper/abstract-geraete-liste/abstract-gerate-liste-component'

@Component({
  selector: 'app-geraete-liste',
  templateUrl: './geraete-liste.component.html',
  styleUrls: ['./geraete-liste.component.css']
})
export class GeraeteListeComponent extends AbstractGerateListeComponent implements OnInit {

  @Input()
  geraete: any[]

  @Input()
  reloadingGerate: boolean = false

  getGeraete() {
    return this.geraete
  }

}
