import {Component, EventEmitter, Input, Output} from '@angular/core'

@Component({
  selector: 'app-gateways-panel',
  templateUrl: './gateways-panel.component.html',
  styleUrls: ['./gateways-panel.component.css']
})
export class GatewaysPanelComponent {

  @Input()
  gateways = []

  selectedGateways = []

  connectedDevices = []

  @Output()
  onSelectionUpdate = new EventEmitter<any>()

  @Output()
  onConnectedDevicesUpdate = new EventEmitter<any>()

  updateSelection(gateway, selected) {
    if (selected)
      this.selectedGateways.push(gateway)
    else
      this.selectedGateways = this.selectedGateways.filter(g => g !== gateway)

    this.onSelectionUpdate.emit({
      connectedDevices: this.selectedGateways.reduce((p, c) => p.concat(c.connectedDevices), []),
      anyGatewaySelected: !!this.selectedGateways.length
    })
  }

  updateConnectedDevices(connectedDevices) {
    this.connectedDevices = this.connectedDevices.concat(connectedDevices || [])
    this.onConnectedDevicesUpdate.emit(this.connectedDevices)
  }

}
