<loading-screen *ngIf="loading"></loading-screen>

<div *ngIf="!loading">
  <form class="col-12">
    <fieldset>
      <div class="form-row">
        <div class="form-group col">
          <label for="startDate">Start Datum:</label>
          <input class="form-control" type="date" [(ngModel)]="startDate" name="startDate" id="startDate" (change)="validateValues()">
        </div>

        <div class="form-group col">
          <label for="startDate">End Datum:</label>
          <input class="form-control" type="date" [(ngModel)]="endDate	" name="endDate" id="endDate" (change)="validateValues()">
        </div>
      </div>
    </fieldset>
  </form>

  <div class="alert alert-danger" *ngIf="!!validationMessage">{{validationMessage}}</div>

  <mat-dialog-actions>
    <button class="btn btn-secondary" (click)="close()">Abbrechen</button>
    <button class="btn btn-primary" (click)="load()">Messungen laden</button>
  </mat-dialog-actions>
</div>
