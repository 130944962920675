<div *ngIf="liegenschaft && liegenschaft['liegenschaftsNr'] !=0 " class="row immobilie_detail">
  <form class="col-12">
    <fieldset>
      <div class="row">
        <div class="form-group col-8 col-sm-8 col-md-6">
          <label for="name">Name</label>
          <div type="text" class="form-control" id="name">{{liegenschaft['name']}}</div>
        </div>
        <div class="form-group col-4 col-sm-4 col-md-4">
          <label for="liegenschaftsnr">Liegenschafts Nr.</label>
          <div type="text" class="form-control" id="liegenschaftsnr">{{liegenschaft['liegenschaftsNr']}}</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-8 col-sm-8 col-md-6">
          <label for="Stadt">Stadt</label>
          <div type="text" class="form-control" id="Stadt">{{liegenschaft['stadt']}}</div>
        </div>
        <div class="form-group col-4 col-sm-4 col-md-4">
          <label for="Strasse">Strasse</label>
          <div type="text" class="form-control" id="Strasse">{{liegenschaft['strasse']}}</div>
        </div>
      </div>
    </fieldset>
  </form>
</div>
